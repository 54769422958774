import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const SourceListFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteData,
  autocompleteOptions,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="autocomplete"
          name="itemCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cikk" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('itemCode', newVal?.value ?? null).then(() => {
              validateField('itemCode');
            });

            const findedItem = autocompleteData.items.find((i) => i.itemCode === newVal.value);
            setFieldValue('quantityUnitId', findedItem?.quantityUnitId ?? null);
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.itemCode !== '' }}
          selectedValue={values.itemCode}
          selectedLabelValue={selectedValues?.item?.name}
          dataset={autocompleteOptions?.items}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="baseQuantity"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Mennyiség" />}
          variant="standard"
          disabled={viewField}
          InputLabelProps={{ shrink: values.baseQuantity && values.baseQuantity !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          disabled
          name="quantityUnitId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység" />}
          variant="standard"
          InputLabelProps={{ shrink: values.quantityUnitId !== '' }}
          selectedValue={values.quantityUnitId}
          selectedLabelValue={selectedValues?.item?.name}
          dataset={autocompleteOptions?.quantityUnit}
        />
      </div>
    </div>
  );
};

export default SourceListFields;
