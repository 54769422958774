import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../../Interface';
import productionService from '../../../service/production.service';

export default function ProductionReports({ productionState }) {
  const {
    setSelectedValues,
    viewField,
    operatorsDisabled,
    setRefetchTable,
    rowSelectionModel,
    setRowSelectionModel,
    refetchTable,
  } = productionState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'workingNumber', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    productionService.getProductions(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'workingNumber',
      headerName: 'Munkaszám',
      type: 'string',
    },
    {
      field: 'customerOrdNumber',
      headerName: 'Vevővi rendelés szám',
      type: 'string',
    },
    {
      field: 'articleNumber',
      headerName: 'Cikkszám',
      type: 'string',
    },
    {
      field: 'articleName',
      headerName: 'Cikknév',
      type: 'string',
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.ProductionReports}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
}
