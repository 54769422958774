import { useState } from 'react';
export const useProduction = () => {
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
    priority: [],
  });

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    rowSelectionModel,
    setRowSelectionModel,
    autocompleteOptions,
    setAutoCompleteOptions,
  };
};
