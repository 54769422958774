import React, { useEffect, useState } from 'react';
import { useSimpleTask } from '../../hooks/useSimpleTask';
import { DialogUI } from '../Interface';
import { SimpleTaskForm } from '../NewSimpleTask/SimpleTaskForm';

const SimpleTaskDialog = ({ open, handleClose, task }) => {
  const simpleTaskState = useSimpleTask();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (task) {
      simpleTaskState.setSelectedValues(task);
      setIsUpdate(true);
    }
  }, [task, simpleTaskState]);
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Feladatok"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <SimpleTaskForm
          dialog={{
            handleClose: handleClose,
            isViewMode: true,
          }}
          simpleTaskState={simpleTaskState}
        />
      </div>
    </DialogUI>
  );
};

export default SimpleTaskDialog;
