import { useEffect, useMemo, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';
import { useLocation } from 'react-router-dom';

export const useProject = () => {
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [dynProps, setDynProps] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
    company: [],
    province: [],
    resourceOne: [],
    resourceTwo: [],
    resourceThree: [],
    resourceFour: [],
    resourceFive: [],
  });

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('Project');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne', 'resourceTwo', 'resourceThree', 'resourceFour', 'machine']);
  }, [menus]);

  return {
    refetchTable: refetchTable || false,
    setRefetchTable: setRefetchTable || (() => {}),
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    rowSelectionModel,
    setRowSelectionModel,
    autoCompleteOptions,
    setAutoCompleteOptions,
    dynProps,
    setDynProps,
  };
};
