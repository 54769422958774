import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridColumns } from '../../../utils/helper';
import {
  DataGridUI,
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  AddButtonUi,
  AutoCompleteSelectUI,
} from '../../Interface';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `productionOperation.${values.productionOperation.length ?? 0}.operationId`,
      `productionOperation.${values.productionOperation.length ?? 0}.resourceOneId`,
      `productionOperation.${values.productionOperation.length ?? 0}.startDate`,
      `productionOperation.${values.productionOperation.length ?? 0}.timeSpent`,
      `productionOperation.${values.productionOperation.length ?? 0}.description`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const ProductionOperations = ({ disabled, defaultField, autocompleteOptions, sourceData, calculateLineQty }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const hasSourceData = Object.keys(sourceData).length > 0;

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.productionOperation.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `productionOperation.${valueIndex}.operationId`,
      `productionOperation.${valueIndex}.resourceOneId`,
      `productionOperation.${valueIndex}.startDate`,
      `productionOperation.${valueIndex}.timeSpent`,
      `productionOperation.${valueIndex}.description`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);

      if (errors?.productionOperation?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'label',
        headerName: 'Művelet azon.',
        flex: 0.5,
      },
      {
        field: 'operationId',
        headerName: 'Művelet',
        flex: 1,
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const findedOperation = autocompleteOptions.operation.find((item) => item.value === data.row.operationId);
          return findedOperation?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find((item) => item.value === data.row.operationId);
          /*console.log(data.row.fromSourceData);
          console.log(hasSourceData);*/
          return (
            <Field
              name={`productionOperation.${valueIndex}.operationId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={
                autocompleteOptions.operation
                /*!hasSourceData || !data.row.fromSourceData
                  ? autocompleteOptions.operation
                  : autocompleteOptions.operation.filter((ac) =>
                      sourceData.sourceListOperation.some((so) => ac.value === so.operationId)
                    )*/
              }
              selectedValue={values.productionOperation?.[valueIndex]?.operationId}
              selectedLabelValue={findedOperation?.label}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.operationId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.operationId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.operationId`)}
            />
          );
        },
      },
      {
        field: 'resourceOneId',
        headerName: 'Erőforrás',
        flex: 1,
        editable: true,
        disableOpenOnFocus: true,
        sortable: false,
        renderCell: (data) => {
          const findedReesourceOne = autocompleteOptions.resourceOne.find(
            (item) => item.value === data.row.resourceOneId
          );
          return findedReesourceOne?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedReesourceOne = autocompleteOptions.resourceOne.find(
            (item) => item.value === data.row.resourceOneId
          );

          const operationLine = values.productionOperation?.[valueIndex];

          const findedOperation = sourceData?.sourceListOperation?.find(
            (so) => so.operationId === operationLine.operationId
          );
          //sourceListOperationMachine
          const dataset = autocompleteOptions.resourceOne;
          /*!hasSourceData || !data.row.fromSourceData
              ? autocompleteOptions.resourceOne
              : autocompleteOptions.resourceOne.filter((ac) =>
                  findedOperation?.sourceListOperationMachine.some((so) => ac.value === so.resourceId)
                );*/

          return (
            <Field
              name={`productionOperation.${valueIndex}.resourceOneId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={dataset}
              selectedValue={values.productionOperation?.[valueIndex]?.resourceOneId}
              selectedLabelValue={findedReesourceOne?.label}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.resourceOneId} />}
              onChange={async (_e, newVal) => {
                await setFieldValue(`productionOperation.${valueIndex}.resourceOneId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });

                //kalkulálni az erőforrás adatok alapján
                calculateLineQty(values, valueIndex);
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.resourceOneId`)}
            />
          );
        },
      },
      {
        field: 'startDate',
        headerName: 'Gy. terv. kezdete',
        flex: 1,
        editable: true,
        sortable: false,
        type: 'dateTime',
        valueGetter: (params) => {
          const value = params.value;
          return value ? new Date(value) : null;
        },
      },
      {
        field: 'timeSpent',
        headerName: 'Ráfordítás',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionOperation.${valueIndex}.timeSpent`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.timeSpent} />}
              onChange={(e) => {
                setFieldValue(`productionOperation.${valueIndex}.timeSpent`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              disabled={hasSourceData}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.timeSpent`)}
            />
          );
        },
      },
      {
        field: 'description',
        headerName: 'Megjegyzés',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionOperation.${valueIndex}.description`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.description} />}
              onChange={(e) => {
                setFieldValue(`productionOperation.${valueIndex}.description`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.description`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="productionOperation">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.ProductionFormTable}
                sx={{ height: 380 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.productionOperation ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default ProductionOperations;
