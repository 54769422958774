import { useState, useEffect } from 'react';

import companyService from '../../service/company.service';
import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';

import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';

export default function CompanyReports({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  dynProps,
  autocompleteOptions,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'companyName', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    companyService.getCompanies(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'companyName',
      headerName: 'Cég neve',
      type: 'string',
    },
    {
      field: 'adress',
      headerName: 'Levelezési cím',
      type: 'string',
      renderCell: (data) => {
        const { postCode, city, street } = data.row;

        return `${postCode}, ${city}, ${street}`;
      },
    },
    {
      field: 'statusId',
      type: 'singleSelect',
      headerName: 'Státusz',
      renderCell: (data) => {
        return data.row.status?.itemName;
      },
      valueOptions: autocompleteOptions.status,
    },
    ...generateColumnsFromDynProps(dynProps ?? []),
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.Company}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
      getRowId={(row) => row.companyId}
    />
  );
}
