import React, { useEffect, useState } from 'react';
import { AutoCompleteSelectUI, ButtonUI, DialogUI, FormLabelUI, InputUI } from '../../Interface';
import CheckListItemTable from './CheckListItemTable';
import TemplateImportDialog from './TemplateImport/TemplateImportDialog';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';

const CheckListDialog = ({
  open,
  handleClose,
  lineId,
  submenuId,
  handleCreate,
  handleUpdate,
  handleDelete,
  rows,
  checkList,
  user,
  templates,
}) => {
  const { selectedChecklist, setSelectedChecklist } = checkList;
  const [isNew, setIsNew] = useState(false);
  const [name, setName] = useState('');
  const [items, setItems] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  useEffect(() => {
    const options = rows.map((row) => ({ label: row.name, value: row.id }));
    setAutoCompleteData(options);
  }, [rows]);

  useEffect(() => {
    if (selectedChecklist) {
      const checklist = rows.find((row) => row.id === selectedChecklist);
      const newItems = checklist?.checkListItem ?? [];
      setItems((prevItems) => [...prevItems, ...newItems]);
    } else {
      setItems([]);
    }
  }, [selectedChecklist]);

  const values = {
    lineId,
    formId: submenuId,
    name: name,
    visOrder: null,
    checkListItem: items,
  };

  const handleSubmit = () => {
    if (items.some((item) => !item.name.trim())) {
      addNotification({
        content: 'Nem maradhat üres mező!',
        type: NotificationType.ERROR,
      });
      return;
    }
    if (isNew) {
      handleCreate(values);
      onClose();
    } else {
      handleUpdate(values);
      onClose();
    }
  };

  const handleAddItemFromTemplate = (item) => {
    const newVisOrder = (items.length || 0) + 1;

    const updatedImportedItems = item.map((item, index) => ({
      ...item,
      visOrder: newVisOrder + index,
    }));

    setItems((prevItems) => [...prevItems, ...updatedImportedItems]);
  };

  const addNewCheckList = () => {
    setIsNew(true);
    setName('');
    setSelectedChecklist(null);
  };

  const deleteCheckList = () => {
    handleDelete();
    setName('');
    setSelectedChecklist(null);
  };

  const onClose = () => {
    handleClose();
    setName('');
    setIsNew(false);
    setItems([]);
    setSelectedChecklist(null);
  };

  const onCancel = () => {
    setIsNew(false);
    setItems([]);
    setName('');
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      fullWidth
      headerContent="Checklista módosítás"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '1000px',
          },
        },
      }}
    >
      <div className="text-labelColor mt-3">
        <div className="flex justify-between">
          <div>
            <ButtonUI text="Új" className="bg-success" onClick={() => addNewCheckList()} />
            <ButtonUI
              text="Checklista törlése"
              className="bg-danger ml-3"
              disabled={isNew || selectedChecklist === null}
              onClick={() => deleteCheckList()}
            />
            <ButtonUI text="Mégsem" className={`${!isNew && 'hidden'} ml-3 bg-warning`} onClick={() => onCancel()} />
          </div>
          <div>
            <ButtonUI text="Template importálás" className="bg-warning" onClick={() => setIsImportDialogOpen(true)} />
          </div>
        </div>
        <div className="flex gap-5">
          <div className={`${isNew && 'hidden'} w-[250px] mb-5 mt-2`}>
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Checklisták" />}
              dataset={autoCompleteData}
              selectedValue={selectedChecklist}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setSelectedChecklist(null);
                  setName('');
                } else {
                  setSelectedChecklist(newVal.value);
                  setName(newVal.label);
                }
              }}
            />
          </div>
          <div className="w-[250px] mt-2 mb-5">
            <InputUI
              label={<FormLabelUI text="Checklista neve" />}
              value={name}
              variant="standard"
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{ shrink: name !== '' }}
            />
          </div>
        </div>
        <CheckListItemTable items={items} setItems={setItems} user={user} />
        <div className="flex justify-center mt-5 gap-2">
          <ButtonUI
            text="Mentés"
            className="bg-success"
            onClick={() => handleSubmit()}
            disabled={(selectedChecklist === null && !isNew) || (isNew && name === '')}
          />
          <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={onClose} />
        </div>
      </div>
      <TemplateImportDialog
        open={isImportDialogOpen}
        handleClose={() => {
          setIsImportDialogOpen(false);
        }}
        templates={templates}
        onItemAdd={handleAddItemFromTemplate}
        checkListName={{ name, setName }}
        isNew={isNew}
      />
    </DialogUI>
  );
};

export default CheckListDialog;
