import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { getDynVals } from '../../utils/DynHelper';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase } from '../Interface';
import ComplaintFields from './ComplaintFields';
import ComplaintReports from './ComplaintReports';
import ComplaintTabs from './ComplaintTabs';
import complaintService from '../../service/complaint.service';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const ComplaintPage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [dynProps, setDynProps] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    items: [],
    bussinessPartners: [],
    status: [],
  });

  const def = {
    date: dayjs(new Date()),
    customer: null,
    item: null,
    statusId: null,
    description: '',
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    getMenus();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('complaint');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .bussinessPartnersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          items: resp[1],
          bussinessPartners: resp[2],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          items: generateAutocompleteItems(aData.items, ['itemCode', 'itemName'], 'itemCode'),
          bussinessPartners: generateAutocompleteItems(
            aData.bussinessPartners,
            ['companyId', 'companyName'],
            'companyId'
          ),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const valuesToForm = () => {
    const { date, customer, item, prefixedId, description, statusId } = selectedValues;

    setInitValues({
      date: dayjs(date),
      customer,
      item,
      statusId,
      prefixedId: prefixedId ?? '',
      description: description ?? defaultValues.description,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    customer: Yup.string().required(),
    item: Yup.string().required(),
    statusId: Yup.string().required(),
    desciption: Yup.string().max(500),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      date: dayjs(values.date).toISOString(),
      createdBy: user.userId,
    };

    complaintService
      .createComplaint(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data[0]);

        setRowSelectionModel([data[0].id]);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      date: dayjs(values.date).toISOString(),
      updatedBy: user.userId,
    };

    complaintService.updateComplaint(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values, resetForm);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    complaintService.deleteComplaint(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
      setInitValues(defaultSelectedValues);
    });
  };

  return (
    <>
      <FormBase
        name="Complaint"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm, validateField, setFieldValue }) => (
                  <Form>
                    <ComplaintFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                      autocompleteOptions={autocompleteOptions}
                    />

                    <ComplaintTabs
                      disabled={viewField}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      submenuId={submenu}
                      setFieldValue={setFieldValue}
                      setDefaultValues={setDefaultValues}
                      setRefetchTable={setRefetchTable}
                      dynProperties={{ dynProps, setDynProps }}
                      errors={errors}
                      values={values}
                      user={user}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <ComplaintReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            defaultValues={defaultValues}
            editButtonVisible={editButtonVisible}
            autocompleteOptions={autocompleteOptions}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            dynProps={dynProps}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
