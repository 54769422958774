import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useMenus from '../../../context/MenuContext';
import masterDataService from '../../../service/masterData.service';
import useUsers from '../../../context/UsersContext';
import resourceOneService from '../../../service/resources/resourceOne.service';
import { getDynVals } from '../../../utils/DynHelper';
import propertyService from '../../../service/property.service';
import { generateAutocompleteItems } from '../../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase } from '../../Interface';
import ResourceOneReports from './ResourceOneReports';
import { ResourceOneFields } from './ResourceOneFields';
import ResoureOneTabs from './ResoureOneTabs';

export const ResourceOnePage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    type: [],
    status: [],
    resourceGroup: [],
    classification: [],
  });
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [group, setGroup] = useState({
    id: [],
  });

  const defaultCommunication = {
    id: null,
    isNew: true,
    message: '',
    userId: user.userId,
    userName: user.userName,
    createdAt: null,
  };

  const def = {
    name: '',
    groupId: null,
    active: 1,
    statusId: null,
    typeId: null,
    generatedName: '',
    classificationId: null,
    communication: [],
  };
  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          groupId: autocompleteData?.resourceGroup?.defaultValue ?? null,
          classificationId: autocompleteData?.classification?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          type: autocompleteData?.type?.defItem,
          resourceGroup: autocompleteData?.resourceGroup?.defItem,
          classification: autocompleteData?.classification?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('resourceOne');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
      getPropertyGroupsForForm();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const getPropertyGroupsForForm = () => {
    propertyService.getPropertyGroupsForSubmenus({ data: [submenu] }).then((data) => {
      setPropertyGroups(data);
    });
  };

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .groupToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .classificationToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          resourceGroup: resp[2],
          classification: resp[3],
        };
        setAutocompleteData(aData);

        const acOptions = {
          type: generateAutocompleteItems(aData.type.typeItems, 'itemName', 'itemId'),
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          resourceGroup: generateAutocompleteItems(aData.resourceGroup.projectGroupListitems, 'itemName', 'itemId'),
          classification: generateAutocompleteItems(aData.classification.classificationItem, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    groupId: Yup.string().nullable(),
    active: Yup.number().nullable(),
    statusId: Yup.string().nullable(),
    typeId: Yup.string().nullable(),
    classificationId: Yup.string().nullable(),
    communication: Yup.array().of(
      Yup.object().shape({
        message: Yup.string().required(),
      })
    ),
  });

  const valuesToForm = () => {
    const { name, groupId, active, statusId, typeId, generatedName, classificationId, resourceOneCommunication } =
      selectedValues;

    setInitValues({
      name,
      groupId,
      active,
      statusId,
      typeId,
      generatedName,
      classificationId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      communication: resourceOneCommunication.map((rc) => ({
        id: rc.id,
        message: rc.message,
        userId: rc.user.userId,
        userName: rc.user.userName,
        createdAt: rc.createdAt,
      })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    resourceOneService.updateResource(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    resourceOneService
      .createResource(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);

        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    resourceOneService.deleteResource(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const handleTemplate = () => {
    resourceOneService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    resourceOneService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    resourceOneService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  return (
    <>
      <FormBase
        name="ResourceOne"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ setFieldValue, resetForm }) => (
                  <Form>
                    <ResourceOneFields
                      viewField={viewField}
                      submenuId={submenu}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                    />
                    <ResoureOneTabs
                      disabled={viewField}
                      submenuId={submenu}
                      setFieldValue={setFieldValue}
                      selectedValues={selectedValues}
                      setDefaultValues={setDefaultValues}
                      dynProperties={{ dynProps, setDynProps }}
                      user={user}
                      setRefetchTable={setRefetchTable}
                      handleImport={handleImport}
                      handleExport={handleExport}
                      handleTemplate={handleTemplate}
                      propertyGroups={propertyGroups}
                      groupState={{ group, setGroup }}
                      defaultField={defaultCommunication}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <ResourceOneReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
            dynProps={dynProps}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
