import { useState, useEffect } from 'react';

import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import complaintService from '../../service/complaint.service';
import { ColorPicker } from '../../config/ColorPicker';
import useDataGridDynamicStyles from '../../hooks/useDataGridDynamicStyles';

const ComplaintReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  dynProps,
}) => {
  useDataGridDynamicStyles(ColorPicker);
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    complaintService.getComplaints(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'prefixedId',
      headerName: 'Sorszám',
    },
    {
      field: 'date',
      type: 'date',
      headerName: 'Érkezés dátuma',
      valueGetter: (_data, row) => {
        return new Date(row.date);
      },
    },
    {
      field: 'customer',
      headerName: 'Vevő',
    },
    {
      field: 'customerName',
      type: 'singleSelect',
      headerName: 'Vevő neve',
      renderCell: (data) => {
        return data.row.customerData?.companyName;
      },
      valueOptions: autocompleteOptions.bussinessPartners,
    },
    {
      field: 'item',
      headerName: 'Cikkszám',
    },
    {
      field: 'itemName',
      headerName: 'Cikknév',
      renderCell: (data) => {
        return data.row.itemData?.itemName;
      },
      valueOptions: autocompleteOptions.items,
    },
    {
      field: 'statusId',
      type: 'singleSelect',
      headerName: 'Státusz',
      flex: 1,
      renderCell: (data) => {
        return data.row.status?.itemName;
      },
      valueOptions: autocompleteOptions.status,
    },
    ...generateColumnsFromDynProps(dynProps ?? []),
  ]);

  const getRowClassName = (params) => {
    const isSelected = rowSelectionModel.includes(params.id);
    const statusColor = ColorPicker.find((col) => col.colorName === params?.row?.status.color);

    if (isSelected) {
      return statusColor ? `color-selected-${statusColor.colorName}` : '';
    }
    return statusColor ? `color-${statusColor.colorName}` : '';
  };

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.complaints}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
      getRowClassName={getRowClassName}
    />
  );
};

export default ComplaintReports;
