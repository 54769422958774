import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import itemService from '../../service/item.service';

export default function ItemReports({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    itemService.getItems(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'itemName',
      headerName: 'Cikknév',
    },
    {
      field: 'itemCode',
      headerName: 'Cikkszám',
    },
    {
      field: 'quantityUnitId',
      type: 'singleSelect',
      headerName: 'Mennyiségi egység',
      renderCell: (data) => {
        return data.row.quantityUnit?.name;
      },
      valueOptions: autocompleteOptions.quantityUnit,
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.item}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      getRowId={(row) => row.itemCode}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
}
