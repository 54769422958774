import { useState } from 'react';

import { FormErrorUI, FormLabelUI, InputUI, TabsUI } from '../../Interface';
import ProductionOperations from './ProductionOperations';
import { Field } from 'formik';

const DescriptionPanel = (props) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="description"
            as={InputUI}
            className={`${props.disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            helperText={<FormErrorUI message={props.errors.description} />}
            disabled={props.disabled}
            InputLabelProps={{ shrink: props.values.description !== '' }}
            multiline
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

const ProductionTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Műveletek',
      content: <ProductionOperations {...props} />,
    },

    {
      id: 2,
      name: 'Megjegyzés',
      content: <DescriptionPanel {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default ProductionTabs;
