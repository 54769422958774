import React, { useState } from 'react';
import dayjs from 'dayjs';
import SimpleTaskDialog from './SimpleTaskDialog';
import { IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ProjectSimpleTaskHistory = ({ selectedValues }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [isdialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = (row) => {
    setIsDialogOpen(true);
    setSelectedRow(row);
  };

  return (
    <div className="max-h-[400px] overflow-y-scroll overflow-x-hidden custom-scrollbar">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-6">
            <th className="border p-3">Kezdő időpont</th>
            <th className="border p-3">Objektum</th>
            <th className="border p-3 col-span-2">Feladat neve</th>
            <th className="border p-3">Típus</th>
            <th className="border p-3"></th>
          </tr>
        </thead>
        <tbody>
          {selectedValues.simpleTask &&
            selectedValues.simpleTask.length > 0 &&
            selectedValues.simpleTask.map((row, index) => (
              <tr
                key={row.simpleTaskId}
                className={
                  index % 2 === 0
                    ? 'bg-gray-100 grid grid-cols-6 hover:bg-[#EDF4FB] cursor-pointer'
                    : 'grid grid-cols-6 hover:bg-[#EDF4FB] cursor-pointer'
                }
                onDoubleClick={() => handleOpen(row)}
              >
                <td className="border py-2 text-center">{dayjs(row.startDate).format('YYYY.MM.DD. HH:mm')}</td>
                <td className="border py-2 text-center">Feladat</td>
                <td className="border py-2 text-center col-span-2">{row.simpleTaskName}</td>
                <td className="border py-2 text-cente">{row?.taskType.itemName}</td>
                <td className="border py-2 text-center">
                  <RemoveRedEyeIcon color="black" onClick={() => handleOpen(row)} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <SimpleTaskDialog open={isdialogOpen} handleClose={() => setIsDialogOpen(false)} task={selectedRow} />
    </div>
  );
};

export default ProjectSimpleTaskHistory;
