import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import masterDataService from '../../service/masterData.service';
import useUsers from '../../context/UsersContext';
import { generateAutocompleteItems } from '../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface';
import itemService from '../../service/item.service';
import { ItemFields } from './ItemFields';
import ItemReports from './ItemReports';

export const ItemPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    quantityUnit: [],
  });

  const def = {
    itemCode: '',
    itemName: '',
    quantityUnitId: '',
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();
    initDefaultValues();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('item');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          quantityUnit: resp[0],
        };

        const acOptions = {
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'quantityUnitName', 'quantityUnitId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    itemCode: Yup.string().required(),
    itemName: Yup.string().required(),
    quantityUnitId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const { itemCode, itemName, quantityUnitId } = selectedValues;

    setInitValues({
      itemCode,
      itemName,
      quantityUnitId,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    itemService.updateItem(updData, selectedValues.itemCode).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    itemService
      .createItem(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.itemCode);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    itemService.deleteItem(deleteData, selectedValues.itemCode).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
      setInitValues(defaultValues);
    });
  };

  return (
    <>
      <FormBase
        name="Item"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setInitValues(defaultValues);
                  setSelectedValues(defaultValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, setFieldValue, errors, validateField, resetForm }) => (
                  <Form>
                    <ItemFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                      autocompleteOptions={autocompleteOptions}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <ItemReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
