import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';
import { divide, multiply } from 'mathjs';
import { v4 as uuidv4 } from 'uuid';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useMenus from '../../../context/MenuContext';
import masterDataService from '../../../service/masterData.service';
import productionService from '../../../service/production.service';
import useUsers from '../../../context/UsersContext';
import { generateAutocompleteItems } from '../../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, ConfirmDialogUI } from '../../Interface';
import ProductionFields from './ProductionFields';
import ProductionTabs from './ProductionTabs';
import sourcelistService from '../../../service/sourcelist.service';

export const ProductionPage = ({ productionState, dialog }) => {
  const {
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    autocompleteOptions,
    setAutoCompleteOptions,
    setRefetchTable,
    setRowSelectionModel,
  } = productionState;

  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [sourceData, setSourceData] = useState({});

  const defaultOperation = {
    id: null,
    isNew: true,
    operationId: null,
    resourceOneId: null,
    startDate: dayjs(new Date()),
    timeSpent: 1,
    description: '',
    createdBy: user.userId,
    label: '',
    fromSourceData: false,
  };

  const def = {
    workingNumber: '',
    customerOrdNumber: '',
    projectId: null,
    articleNumber: '',
    articleName: '',
    productionQuantity: 1,
    me: null,
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    statusId: null,
    priorityId: null,
    companyId: null,
    description: '',
    productionOperation: [],
    userId: null,
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorityId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    if (user) {
      setDefaultValues((prev) => {
        return { ...prev, userId: user?.userId };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          user: user,
        };
      });
    }
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          priority: resp[1],
          company: resp[2],
          user: resp[3],
          project: resp[4],
          resourceOne: resp[5],
          operation: resp[6],
          quantityUnit: resp[7],
          items: resp[8],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          priority: generateAutocompleteItems(aData.priority.priorityListItems, 'itemName', 'itemId'),
          company: generateAutocompleteItems(aData.company, 'companyName', 'companyId'),
          user: generateAutocompleteItems(aData.user, 'userName', 'userId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          operation: generateAutocompleteItems(aData.operation, 'operationName', 'operationId'),
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'quantityUnitName', 'quantityUnitId'),
          items: generateAutocompleteItems(aData.items, ['itemCode', 'itemName'], 'itemCode'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    workingNumber: Yup.string().nullable(),
    customerOrdNumber: Yup.string(),
    projectId: Yup.string().nullable(),
    articleNumber: Yup.string().required(),
    articleName: Yup.string().required(),
    productionQuantity: Yup.number().nullable().positive(),
    me: Yup.string().nullable().required(),
    startDate: Yup.string(),
    endDate: Yup.string(),
    statusId: Yup.string().nullable(),
    priorityId: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    description: Yup.string(),
    productionOperation: Yup.array().of(
      Yup.object().shape({
        operationId: Yup.string().required(),
        resourceOneId: Yup.string().required(),
        startDate: Yup.string().required(),
        timeSpent: Yup.number().required().positive(),
        description: Yup.string().nullable(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate,
      endDate,
      statusId,
      priorityId,
      userId,
      companyId,
      description,
      productionOperation,
    } = selectedValues;

    setInitValues({
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      statusId,
      priorityId,
      userId,
      companyId,
      description,
      productionOperation:
        productionOperation &&
        productionOperation.map((po) => ({
          id: po.id,
          operationId: po.operationId,
          resourceOneId: po.resourceOneId,
          startDate: po.startDate,
          timeSpent: po.timeSpent,
          description: po.description,
          createdBy: po.user?.userId,
          label: po.label,
          fromSourceData: po.fromSourceData,
        })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    productionService.updateProduction(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    productionService
      .createProduction(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        if (dialog) {
          dialog.setProductionChanged(true);
          dialog.handleClose();
        }
        setRowSelectionModel(data.id);
        setSelectedValues(data);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    productionService.deleteProduction(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const getSourceData = async (itemCode) => {
    if (!itemCode) {
      setSourceData({});
      return;
    }

    const sd = await sourcelistService.getSourcelistByItemCode(itemCode);
    setSourceData(sd);
    if (Object.keys(sd).length > 0 && !viewField) setShowConfirmDialog(true);
  };

  const addSourceLine = (setFieldValue, values) => {
    //productionOperation

    const newLines = sourceData.sourceListOperation.map((mp) => {
      const result = multiply(
        divide(mp.sourceListOperationMachine[0].neededQuantity ?? 1, sourceData.baseQuantity ?? 1),
        values.productionQuantity ?? 1
      );
      return {
        id: uuidv4(),
        isNew: true,
        operationId: mp.operationId,
        resourceOneId: mp.sourceListOperationMachine[0].resourceId,
        startDate: dayjs(new Date()),
        timeSpent: mp.sourceListOperationMachine[0].neededQuantity,
        description: '',
        createdBy: user.userId,
        label: '',
        fromSourceData: true,
      };
    });

    setFieldValue('productionOperation', newLines);
    setShowConfirmDialog(false);
  };

  return (
    <>
      <>
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => {
              const calc = (ind, val) => {
                const findedOp = sourceData.sourceListOperation.find((so) => so.operationId === val?.operationId);
                const findedRes = findedOp?.sourceListOperationMachine.find(
                  (om) => om.resourceId === val.resourceOneId
                );

                const result = multiply(
                  divide(findedRes?.neededQuantity ?? 1, sourceData.baseQuantity ?? 1),
                  values.productionQuantity ?? 1
                );

                setFieldValue(`productionOperation.${ind}.timeSpent`, 1 ?? '');
              };

              const calculateLineQty = (index) => {
                if (Object.keys(sourceData).length > 0) {
                  if (index) {
                    calc(index, values.productionOperation[index]);
                  }

                  for (const [i, val] of values.productionOperation.entries()) {
                    calc(i, val);
                  }
                }
              };

              return (
                <Form>
                  <ProductionFields
                    viewField={viewField}
                    submenuId={submenu}
                    selectedValues={selectedValues}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    validateField={validateField}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    getSourceData={getSourceData}
                    calculateLineQty={calculateLineQty}
                  />
                  <ProductionTabs
                    disabled={viewField}
                    submenuId={submenu}
                    setFieldValue={setFieldValue}
                    selectedValues={selectedValues}
                    setDefaultValues={setDefaultValues}
                    user={user}
                    setRefetchTable={setRefetchTable}
                    defaultField={defaultOperation}
                    values={values}
                    errors={errors}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    sourceData={sourceData}
                    calculateLineQty={calculateLineQty}
                  />
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                    disabled={editButtonVisible && addField}
                  />
                  <ConfirmDialogUI
                    handleChangeElement={() => {
                      addSourceLine(setFieldValue, values);
                    }}
                    show={showConfirmDialog}
                    onHide={() => {
                      setShowConfirmDialog(false);
                      setSourceData({});
                    }}
                    message={'A válaszott cikkhez tartozik forrásjegyzék. Importálja a forrásjegyzéket?'}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </>

      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
