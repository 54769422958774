import React, { useState, useEffect, useMemo } from 'react';

import resourceOneService from '../../../service/resources/resourceOne.service';
import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../../utils/helper';
import { DataGridIdentifiers, OrderArrowsUI, DatagridPremiumUi } from '../../Interface';

export default function ResourceOneReports({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  dynProps,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    resourceOneService.getResources(gridState).then((data) => {
      setReports(data);
    });
  };

  const showActive = (params) => {
    //@TODO eltakarítani táblával együtt
    const { active } = params.row;
    if (active === 1) {
      return 'Igen';
    } else if (active === 2) {
      return 'Nem';
    } else {
      return 'Nincs adat';
    }
  };
  const orderMachine = (id, newOrder) => {
    resourceOneService.orderResource(id, { newOrder }).then(() => {
      setRefetchTable(true);
    });
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;
    orderMachine(id, newOrder);
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        type: 'string',
        headerName: 'Név',
        isRequired: true,
      },
      {
        field: 'groupId',
        type: 'singleSelect',
        headerName: 'Csoport',
        renderCell: (data) => {
          return data.row.resourceGroup?.itemName;
        },
        valueOptions: autocompleteOptions.resourceGroup,
      },
      {
        field: 'statusId',
        type: 'singleSelect',
        headerName: 'Státusz',
        renderCell: (data) => {
          return data.row.status?.itemName;
        },
        valueOptions: autocompleteOptions.status,
      },
      {
        field: 'active',
        type: 'string',
        headerName: 'Aktív',
        renderCell: (data) => {
          return showActive(data);
        },
        sortable: false,
      },
      {
        field: 'typeId',
        type: 'singleSelect',
        headerName: 'Típus',
        renderCell: (data) => {
          return data.type?.itemName;
        },
        sortable: false,
        valueOptions: autocompleteOptions.type,
      },
      {
        field: 'classificationId',
        type: 'singleSelect',
        headerName: 'Besorolás',
        renderCell: (data) => {
          return data.row.classification?.name;
        },
        sortable: false,
        valueOptions: autocompleteOptions.classification,
      },
      {
        field: 'visOrder',
        type: 'actions',
        headerName: 'Sorrend',
        renderCell: (data) => {
          const handleUpClick = () => handleOrderChange(data.row.id, data.row.visOrder, 'up');
          const handleDownClick = () => handleOrderChange(data.row.id, data.row.visOrder, 'down');
          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} />;
        },
        filterable: false,
      },
      ...generateColumnsFromDynProps(dynProps ?? []),
    ]);
  }, [autocompleteOptions, dynProps]);

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && Object.keys(dynProps).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.resourceOne}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
        />
      )}
    </>
  );
}
