import { useMemo, useState } from 'react';

import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, InputUI, TabsUI, FileTabUI, DeleteDialogUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import ResourceOneTable from './Resources/ResourceOneTable';
import ResourceTwoTable from './Resources/ResourceTwoTable';
import ResourceThreeTable from './Resources/ResourceThreeTable';
import ResourceFourTable from './Resources/ResourceFourTable';
import ResourceFiveTable from './Resources/ResourceFiveTable';
import ProjectContact from './ProjectContact';
import ProjectCommunication from './ProjectCommunication';
import { TabVisibilityConfig } from '../../config/tabVisibilityConfig';
import projectService from '../../service/project.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import CheckLists from '../CheckList/CheckListTab/CheckLists';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="projectDescription"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Leírás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.projectDescription} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.projectDescription !== '' }}
            multiline
            rows={10}
          />
        </div>
      </div>
    </div>
  );
};

const FormTabs = ({
  submenuId,
  setFieldValue,
  disabled,
  setRefetchTable,
  setResourceList,
  resourceList,
  user,
  submenuNames,
  selectedValues,
  setSelectedValues,
  errors,
  values,
  params,
  contact,
  communication,
  canEdit,
  setDefaultValues,
  dynProperties,
  infoPanel,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [removeElement, setRemoveElement] = useState(null);
  const [deleteDialog, setDeletDialog] = useState(false);

  const handleUpload = (files) => {
    if (!selectedValues.projectId) {
      return addNotification({
        content: 'Nincs kiválasztott elem!',
        type: NotificationType.ERROR,
      });
    }
    projectService.uploadFile(files, selectedValues.projectId, user.userId).then((data) => {
      setSelectedValues((prev) => ({ ...prev, projectAttachment: data }));
    });
  };

  const handleDelete = (data) => {
    setRemoveElement(data.id);
    setDeletDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeletDialog(false);
    setRefetchTable(null);
  };
  const handleRemoveElement = (data) => {
    projectService.deleteFile(removeElement, user.userId).then((data) => {
      setSelectedValues((prev) => ({ ...prev, projectAttachment: data }));
      hideDeleteDialog();
    });
  };

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: (
        <DynamicPropertyMain
          submenuId={submenuId}
          setFieldValue={setFieldValue}
          disabled={disabled}
          selectedValues={selectedValues}
          canEdit={canEdit}
          setDefaultValues={setDefaultValues}
          dynProperties={dynProperties}
        />
      ),
      key: 'PROPERTIES',
    },
    {
      id: 1,
      name: 'Checklista',
      content: (
        <CheckLists
          checkLists={selectedValues?.checkLists ?? []}
          submenuId={submenuId}
          user={user}
          lineId={selectedValues.projectId}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
        />
      ),
      key: 'CHECKLIST',
    },
    {
      id: 2,
      name: 'Megjegyzés',
      content: <DescriptionPanel errors={errors} values={values} disabled={disabled} />,
      key: 'DESCRIPTION',
    },
    {
      id: 3,
      name: submenuNames[0],
      content: (
        <ResourceOneTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
          canEdit={canEdit}
          params={params}
        />
      ),
      key: 'RESOURCE_ONE',
    },
    {
      id: 4,
      name: submenuNames[1],
      content: (
        <ResourceTwoTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
          canEdit={canEdit}
        />
      ),
      key: 'RESOURCE_TWO',
    },
    {
      id: 5,
      name: submenuNames[2],
      content: (
        <ResourceThreeTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
          canEdit={canEdit}
        />
      ),
      key: 'RESOURCE_THREE',
    },
    {
      id: 6,
      name: 'Kapcsolattartó',
      content: (
        <ProjectContact
          selectedValues={selectedValues}
          disabled={disabled}
          setRefetchTable={setRefetchTable}
          contact={contact}
          user={user}
          params={params}
          canEdit={canEdit}
        />
      ),
      key: 'CONTACT',
    },
    {
      id: 7,
      name: 'Csatolmányok',
      content: (
        <FileTabUI
          disabled={disabled}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          savedFiles={selectedValues.projectAttachment ?? []}
        />
      ),
      key: 'ATTACHMENT',
    },
    {
      id: 8,
      name: 'Kommunikáció',
      content: (
        <ProjectCommunication
          selectedValues={selectedValues}
          disabled={disabled}
          setRefetchTable={setRefetchTable}
          communication={communication}
          user={user}
        />
      ),
      key: 'COMMUNICATION',
    },
    {
      id: 9,
      name: submenuNames[3],
      content: (
        <ResourceFourTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
          canEdit={canEdit}
        />
      ),
      key: 'RESOURCE_FOUR',
    },
    {
      id: 10,
      name: submenuNames[4],
      content: (
        <ResourceFiveTable
          resourceList={resourceList}
          setResourceList={setResourceList}
          setRefetchTable={setRefetchTable}
          disabled={disabled}
          selectedValues={selectedValues}
          user={user}
          canEdit={canEdit}
        />
      ),
      key: 'RESOURCE_FIVE',
    },
  ];

  const visibleTabs = useMemo(() => {
    const tabVisibilityMap = params.PROJECT_TAB_FIELDS
      ? JSON.parse(params.PROJECT_TAB_FIELDS).reduce((acc, field) => {
          const [key, value] = Object.entries(field)[0];
          acc[key] = value === 'Y';
          return acc;
        }, {})
      : {};

    return tabs.filter((tab) => {
      if (!canEdit && infoPanel && TabVisibilityConfig[tab.key]?.hideWhenCanEditFalse) {
        return false;
      }
      if (tab.key === 'PROPERTIES' && tab.key === 'CHECKLIST') {
        return true;
      }
      if (!params.PROJECT_TAB_FIELDS) {
        return true;
      }
      if (tab.key.startsWith('RESOURCE_')) {
        const resourceIndex = parseInt(tab.key.split('_')[1]) - 1;
        return tabVisibilityMap[tab.key] && submenuNames[resourceIndex] !== null;
      }
      return tabVisibilityMap[tab.key];
    });
  }, [params.PROJECT_TAB_FIELDS, tabs, submenuNames]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {visibleTabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
      <DeleteDialogUI handleRemoveElement={handleRemoveElement} show={deleteDialog} onHide={() => hideDeleteDialog()} />
    </div>
  );
};

export default FormTabs;
